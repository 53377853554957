footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  padding: 20px 0;
  background: #307aba;
  width: 100%;
  background-color: #f5f5f5;
}
footer a {
  color: black;
  padding-top: 10px;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
.App-header h1 {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 30px;
}

.App-header__fondo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100%;
  overflow: hidden;
  height: auto;
  opacity: 80%;
  margin-top: -5px;
}
.App-header__image {
  width: 50%;
  margin-bottom: -34px;
}
@media (min-width: 320px) {
  .App-header__image {
    margin-bottom: -29px;
  }
}
@media (min-width: 330px) {
  .App-header__image {
    margin-bottom: -30px;
  }
}
@media (min-width: 340px) {
  .App-header__image {
    margin-bottom: -31px;
  }
}
@media (min-width: 350px) {
  .App-header__image {
    margin-bottom: -32px;
  }
}
@media (min-width: 375px) {
  .App-header__image {
    margin-bottom: -34px;
  }
}
@media (min-width: 411px) {
  .App-header__image {
    margin-bottom: -37px;
  }
}
@media (min-width: 425px) {
  .App-header__image {
    margin-bottom: -38px;
  }
}
@media (min-width: 427px) {
  .App-header__image {
    width: 30%;
    margin-bottom: -42px;
  }
}
@media (min-width: 1024px) {
  .App-header__image {
    width: 30%;
    margin-bottom: -56px;
  }
}
@media (min-width: 1440px) {
  .App-header__image {
    width: 30%;
    margin-bottom: -80px;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 60px 60px 0 0;
  background: #f5f5f5;
}

.main-container__btn-up {
  background-image: linear-gradient(
    270deg,
    rgb(48, 122, 186) 0%,
    rgb(94, 49, 217) 100%
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 79px;
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 26px;
  border: none;
  cursor: pointer;
  width: 237px;
  height: 52px;
}

.main-container__btn,
.main-container__btnComunidad {
  background-image: linear-gradient(
    270deg,
    rgb(48, 122, 186) 0%,
    rgb(94, 49, 217) 100%
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 27px;
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 26px;
  border: none;
  cursor: pointer;
  width: 237px;
  height: 52px;
}
.main-container__btnComunidad {
  justify-content: center;
}

.main-container__btn:hover,
.main-container__btn-up:hover,
.main-container__btnComunidad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.main-container__btn-app {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 27px;
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  color: white;
  border-radius: 26px;
  cursor: pointer;
  width: 237px;
  height: 52px;
  background: transparent;
  border: 2px solid #f5f5f5;
}
.main-container__btn-app.celu {
  border: none;
  margin-top: 10px;
}
.main-container__btn-app-up {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  color: white;
  border-radius: 26px;
  cursor: pointer;
  width: 237px;
  height: 52px;
  background: transparent;
  border: 2px solid #f5f5f5;
}
.main-container__btn-app-up.celu {
  border: none;
  height: auto;
  width: auto;
}
.container-apps {
  background-image: linear-gradient(
    270deg,
    rgb(48, 122, 186) 0%,
    rgb(94, 49, 217) 100%
  );
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 67px;
}
.container-apps.abajo {
  margin-top: -17px;
}
.container-apps h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

.apps-title {
  color: white;
  padding: 0;
  margin: 30px 0 0 0;
}

.apps-title.dos {
  color: white;
  padding: 0 30px;
  text-align: center;
}
a {
  font-size: 15px;
  font-weight: bold;
}
.video-player {
  width: 320px !important;
  height: 185px !important;
  margin: 60px 0;
}
.foto-perfil {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.foto-perfil img {
  width: 60%;
}
.texto-bio p {
  text-align: center;
  font-size: 16px;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .video-player {
    width: 500px !important;
    height: 300px !important;
    margin: 60px 0;
  }
  .foto-perfil img {
    width: 30%;
  }
}
